import React, { Component } from 'react';
import Barras from './products/Barras';
import Blanques from './products/Blanques';
import Bobinas from './products/Bobinas';
import Cantoneiras from './products/Cantoneiras';
import ChapaGrossa from './products/ChapaGrossa';
import FitaTiras from './products/FitaTiras';
import TubosComCostura from './products/TubosComCostura';
import TubosSemCostura from './products/TubosSemCostura';
import Chapas201 from './products/Chapas201';
import Chapas304 from './products/Chapas304';
import Chapas316 from './products/Chapas316';
import Chapas410 from './products/Chapas410';
import Chapas420 from './products/Chapas420';
import Barras304 from './products/Barras304';
import Barras310 from './products/Barras310';
import Barras316 from './products/Barras316';
import Barras410 from './products/Barras410';
import Barras420 from './products/Barras420';
import Barras430 from './products/Barras430';
import Tubos201 from './products/Tubos201';
import Tubos304 from './products/Tubos304';
import TubosPolidos from './products/TubosPolidos';
import TubosOd from './products/TubosOd';
import TubosQuadrados from './products/TubosQuadrados';
import TubosRetangulares from './products/TubosRetangulares';
import CorteBarras from './products/CorteBarras';
import CorteChapas from './products/CorteChapas';

let cssClass = process.env.REACT_APP_HOTSITE.replace(/[^a-zA-Z]+/g, '');

if (cssClass.startsWith('tubos')) {
    cssClass = 'tubos';
} else if(cssClass.startsWith('cortebarras')) {
    cssClass = 'barras';
} else if(cssClass.startsWith('cortechapas')) {
    cssClass = 'chapas';
}

export const baseClass = cssClass;


class ProductSpecifications extends Component {

    static componentMapping = {
        chapas201: Chapas201,
        chapas304: Chapas304,
        chapas316: Chapas316,
        chapas410: Chapas410,
        chapas420: Chapas420,

        barras304: Barras304,
        barras310: Barras310,
        barras316: Barras316,
        barras410: Barras410,
        barras420: Barras420,
        barras430: Barras430,

        tubos201: Tubos201,
        tubos304: Tubos304,
        tubosod: TubosOd,
        tubospolidos: TubosPolidos,
        tubosquadrados: TubosQuadrados,
        tubosretangulares: TubosRetangulares,

        cortebarras: CorteBarras,
        cortechapas: CorteChapas,


    };

    render() {
        const Product = ProductSpecifications.componentMapping[process.env.REACT_APP_HOTSITE];

        return (
            <div className={'product-specifications-wrapper ' + baseClass + ' ' + process.env.REACT_APP_HOTSITE}>
                <Product />
            </div>
        );
    }
}

export default ProductSpecifications;
