const products = {

    chapas201: {
        title: 'Chapas de Aço Inox 201',
        title_html: 'Chapas <br /><span>de Aço Inox 201</span>',
    },

    chapas304: {
        title: 'Chapas de Aço Inox 304',
        title_html: 'Chapas <br /><span>de Aço Inox 304</span>',
    },

    chapas316: {
        title: 'Chapas de Aço Inox 316',
        title_html: 'Chapas <br /><span>de Aço Inox 316</span>',
    },

    chapas410: {
        title: 'Chapas de Aço Inox 410/D',
        title_html: 'Chapas <br /><span>de Aço Inox 410/D</span>',
    },

    chapas420: {
        title: 'Chapas de Aço Inox 420',
        title_html: 'Chapas <br /><span>de Aço Inox 420</span>',
    },



    barras304: {
        title: 'Barras de Inox 304/L',
        title_html: 'Barras <br /><span>de Inox 304/L</span>',
    },

    barras310: {
        title: 'Barras de Inox 310',
        title_html: 'Barras <br /><span>de Inox 310</span>',
    },

    barras316: {
        title: 'Barras de Inox 316/L',
        title_html: 'Barras <br /><span>de Inox 316/L</span>',
    },

    barras410: {
        title: 'Barras de Inox 410',
        title_html: 'Barras <br /><span>de Inox 410</span>',
    },

    barras420: {
        title: 'Barras de Inox 420',
        title_html: 'Barras <br /><span>de Inox 420</span>',
    },

    barras430: {
        title: 'Barras de Inox 430',
        title_html: 'Barras <br /><span>de Inox 430</span>',
    },


    tubos201: {
        title: 'Tubos de Inox 201',
        title_html: 'Tubos <br /><span>de Inox 201</span>',
    },

    tubos304: {
        title: 'Tubos de Inox 304',
        title_html: 'Tubos <br /><span>de Inox 304</span>',
    },

    tubosod: {
        title: 'Tubos de Inox OD',
        title_html: 'Tubos <br /><span>de Inox OD</span>',
    },

    tubospolidos: {
        title: 'Tubos de Inox Polidos',
        title_html: 'Tubos <br /><span>de Inox Polidos</span>',
    },

    tubosquadrados: {
        title: 'Tubos Quadrados de Inox',
        title_html: 'Tubos <br /><span>Quadrados de Inox</span>',
    },

    tubosretangulares: {
        title: 'Tubos Retangulares de Inox',
        title_html: 'Tubos <br /><span>Retangulares de Inox</span>',
    },

    cortebarras: {
        title: 'Corte de Barras',
        title_html: 'Corte <br /><span>de Barras</span>',
    },

    cortechapas: {
        title: 'Corte de Chapas',
        title_html: 'Corte <br /><span>de Chapas</span>',
    },



    barras: {
        title: 'Barras de Aço Inox',
        title_html: 'Barras <br /><span>de Aço Inox</span>',
        description: `Possuímos um extenso e diversificado estoque de barras de aço inox redondas, quadradas, retangulares e sextavadas para atender as suas especificações.`
    },

    blanques: {
        title: 'Blanque de Aço Inox',
        title_html: 'Blanque <br /><span>de Aço Inox</span>',
        description: `Obtidos pelo corte de chapas de aço inox em equipamentos de corte transversal (guilhotina) ou a partir de fitas/tiras (blanqueadeiras), de acordo com as suas especificações.`
    },

    bobinas: {
        title: 'Bobinas de Aço Inox',
        title_html: 'Bobinas <br /><span>de Aço Inox</span>',
        description: `A Jatinox possui um amplo e variado estoque de Bobinas de aço inox Laminadas a Quente e a Frio`
    },

    cantoneiras: {
        title: 'Cantoneiras de Aço Inox',
        title_html: 'Cantoneiras <br /><span>de Aço Inox</span>',
        description: `A cantoneira de aço inox é fornecida em duas opções: Dobrada e Laminada. Possuindo essas variações a Cantoneira de Aço Inox possui uma grande gama de especificações que se encaixam ao atendimento total das necessidades dos nossos clientes.`
    },

    chapa: {
        title: 'Chapas de Aço Inox',
        title_html: 'Chapas <br /><span>de Aço Inox</span>',
        description: `Obtidas pelo corte mecânico das bobinas de aço inox laminadas a quente ou a frio, em equipamentos de corte transversal.`
    },

    'chapa-grossa': {
        title: 'Chapas Grossas de Aço Inox',
        title_html: 'Chapas <br /><span class="second">Grossas</span><br /><span class="last">de Aço Inox</span>',
        description: `Chapas de aço inox em grossas espessuras que não são possíveis de se enrolar em bobinas.`
    },

    'fita-tiras': {
        title: 'Fita e Tira de Aço Inox',
        title_html: 'Fita e Tira <br /><span>de Aço Inox</span>',
        description: `A Fita inox é obtida por corte mecânico longitudinal ("slitter") de bobinas de aço inox com larguras maiores. A Fita inox possui espessura de 0,40mm a 3,00mm e diâmetro interno do rolo de 400mm ou 500mm.`
    },

    'tubos-com-costura': {
        title: 'Tubos com costura de Aço Inox',
        title_html: 'Tubos com <br /><span class="second">Costura</span><br /><span class="last">de Aço Inox</span>',
        description: `Variado estoque de tubos de aço inox com costura, em diversas formas (redondo, quadrado e retangular) para atender a necessidade da sua empresa.`
    },

    'tubos-sem-costura': {
        title: 'Tubos sem costura de Aço Inox',
        title_html: 'Tubos sem <br /><span class="second">Costura</span><br /><span class="last">de Aço Inox</span>',
        description: `O tubo sem costura de aço inox, ao contrário do tubo com costura, é produzido através do aquecimento de um cilindro de aço, o mesmo é 
        aquecido até que fique oco e possa ser perfurado. Sendo assim, o Tubo sem costura não passa pelo processo de soldagem, tendo uma superfície lisa.`
    },

};

export const getProduct = () => {
    return products[process.env.REACT_APP_HOTSITE];
};